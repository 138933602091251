body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rdn-drop {
  top: auto;
  bottom: 100%;
}

.rdn-control {
  padding: 8px 25px 8px 10px;
  border: 1px solid #ececec;
  border-radius: 3px;
}